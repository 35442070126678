<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div class="row g-3">
            <div class="col-md-4">

                <!-- Profile image -->
                <div class="profile-img-card mb-3">
                    <div class="profile-img-card-header"></div>
                    <div class="profile-img">
                        <img height="100" width="100" src="@/assets/img/user.png">
                        <span class="user-name">{{ profile.name }}</span>
                        <span class="user-email">{{ profile.email }}</span>
                    </div>
                </div>

                <!-- Profile details -->
                <!-- <div class="profile-details-card">
                    <h6>Your interests</h6>
                    <ul v-if="categories" class="user-interests">
                        <li v-for="c in categories" :key="c.id">
                            <a @click.prevent="addSelected(c)" href="#">{{ c.name }}</a>
                        </li>
                    </ul>
                </div> -->

            </div>

            <div class="col-md-8">
                 <div class="survey-list-container">

                    <!-- Spinner to display while posts are loading -->
                    <div v-if="wait" class="list-spinner d-flex justify-content-center mb-3">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>

                    <!-- Empty store shows if there's an error -->
                    <div v-if="!wait && error" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>{{ error }}</h4>
                            <h6>An error occurred while trying to fetch your posts.</h6>
                        </div>
                    </div>                    

                    <!-- Empty store shows if post count == 0 -->
                    <!-- <div v-if="!wait && !error && surveys.data.length == 0" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/empty-box.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>There are no posts to display.</h4>
                            <h6>There are no items to display for the selected view.</h6>
                        </div>
                    </div> -->

                    <!-- List of posts -->
                    <ul class="survey-list">
                        <li v-for="survey in surveys.data" :key="survey.id" class="survey-list-item">
                            <h6>
                                  <router-link :to="`/surveys/${survey.code}/${survey.slug}`">
                                    <span class="fas fa-clipboard-list"></span>
                                    {{ survey.title }}
                                </router-link>
                            </h6>
                            <p class="excerpt">
                                {{ survey.shortDescription }}
                                <a href="#" v-for="t in survey.getTags" :key="t">{{ t }}&nbsp;</a>
                            </p>
                            <p class="tags">
                                <span class="text-muted">Tags: </span>
                                <a href="#" v-for="t in survey.getTags" :key="t">{{ t }}&nbsp;</a>
                            </p>
                            <div class="survey-user">
                            <span class="datestamp">
                                {{ moment(survey.created_at).format("MMM D, YYYY HH:mm") }}
                            </span>
                            </div>

                            <div class="post-status">
                            <span v-if="!survey.published" class="status-badge draft">
                                <i class="fas fa-circle"></i>
                                Draft
                            </span>
                                <span v-else-if="!survey.active" class="status-badge inactive">
                                <i class="fas fa-circle"></i>
                                Inactive
                            </span>
                                <span v-else class="status-badge active">
                                <i class="fas fa-circle"></i>
                                Active
                            </span>
                            </div>

                            <b-alert show variant="warning" v-if="survey.flags_count !== 0" class="mb-0 mt-5">
                                <p class="mb-0 font-weight-semibold"><i class="danger me-2 fas fa-exclamation-triangle"></i>Your survey has been flagged {{ survey.flags_count }} time(s)</p>
                                <small>Please note that if your survey is flagged more than 3 times, it will be pulled down for review.</small>
                                <div v-if="survey.flags">
                                    <hr />
                                    <ul class="fa-ul">
                                        <li v-for="f in survey.flags" :key="f.id">
                                            <span class="fa-li"><i class="danger fas fa-flag"></i></span>{{ f.reason }} ({{ moment(f.created_at).format("MMM D, YYYY HH:mm") }})
                                        </li>
                                    </ul>
                                </div>
                            </b-alert>

                            <!-- <div class="survey-list-item-actions">
                                <ul>
                                    <li>
                                        <router-link :to="`/profile/surveys/view/${survey.code}/questionnaire`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.questions_count }}</span>
                                            <span class="small-desc">Questions</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="`/profile/surveys/view/${survey.code}/respondents`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.respondents_count }}</span>
                                            <span class="small-desc">Respondents</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <b-dropdown right no-caret variant="link" :id="`dropdown-${survey.code}`"
                                                    toggle-class="text-decoration-none"
                                                    class="survey-list-item-options">
                                            <template #button-content>
                                                <i class="fas fa-chevron-down"></i>
                                            </template>
                                            <b-dropdown-item>
                                                <router-link :to="`/profile/surveys/view/${survey.code}/questionnaire`">
                                                    Edit form
                                                </router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <router-link :to="`/profile/surveys/view/${survey.code}/respondents`">
                                                    Respondents
                                                </router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <a
                                                    :class="!survey.published ? 'disabled' : ''"
                                                    href="#" @click.prevent="toggleSurveyActivation(survey)">
                                                    <template v-if="survey.active">
                                                        Deactivate
                                                    </template>
                                                    <template v-else>Activate</template>
                                                </a>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <router-link :to="'/profile/surveys/create/'+survey.code">
                                                    Edit survey details
                                                </router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <a class="text-danger" @click.prevent="deleteSurvey(survey)">
                                                    Delete Survey
                                                </a>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </li>
                                </ul>
                            </div> -->
                        </li>
                    </ul>

                </div><!-- End survey list container -->

                <!-- Pagination -->
                <Pagination
                    :limit="1"
                    :showDisabled="true"
                    :data="surveys"
                    @pagination-change-page="loadSurveys">
                </Pagination>
            </div>
        </div>
    </div>
</template>

<script>
// import Alerts from "../Helpers/Alerts";
import Pagination from 'laravel-vue-pagination'
export default {
    name: "PostList",
    props: ['id'],
    components: {Pagination},
    data() {
        return {
            surveys: {data:[]},
            survey: '',
            profile: {
                name: "",
                username: "",
                email: "",
                phone: "",
                dob: new Date(),
            },
            selected: [],
            categories: []
        }
    },
    mounted() {
        this.getProfile()
        this.getCategories()
        this.loadSurveys()
    },
    methods: {
        addSelected(cat) {
            console.log(cat)
            let index = this.selected.indexOf(cat)
            if (index === -1) {
                this.selected.push(cat)
            } else {
                this.selected.splice(index, 1);
            }
        },
        loadSurveys(page = 1) {
            window.scroll(0,0)
            this.setLoading()
            this.$http.get('/api/profile/surveys/' + '?page=' + page).then(res => {
                this.surveys = res.data.data
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        getProfile() {
            this.$http.get("/api/profile").then(response => {
                this.profile = response.data.data
            });
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        },
        saveProfile() {
            this.clear()
            this.wait = true
            this.profile.dob = this.formatDate(this.profile.dob)
            this.$http.post("/api/profile", this.profile).then(response => {
                let msg = 'Your profile updated successfully!'
                this.$toaster.success(msg)
                this.success = msg
                this.profile = response.data.data
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            });
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
    }
}
</script>

<style scoped>
.label {
    display: inline !important;
    padding: 3px 6px;
    border: 1px solid #0B2559;
    border-radius: 6px;
    margin-right: 4px;
    transition: all .3s
}

.label:hover {
   opacity: .8;
}

.label.selected {
    color: white;
    background-color: #0B2559;
}

h4 {
    font-weight: bold;
}

label {
    margin-bottom: 4px;
}
</style>